import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import httpBuildQuery from 'http-build-query'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const ProcessService = {
  // Obter atual responsável do processo
  // localhost/app_dev.php/api/{version}/process/{idProcess}/answerable/current
  getProcessResponsable (idProcess) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}/answerable/current`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Parametros do processo
  // localhost/app_dev.php/api/{version}/process/{idProcess}
  getProcessParams (idProcess) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Processos arquivos
  // localhost/app_dev.php/api/v2/process/{idProcess}/files
  getProcessFiles (idProcess) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}/files`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Obtém arquivo de um processo
  // localhost/app_dev.php/api/v2/process/{idProcess}/file/{idFile}
  getProcessFile (idProcess, idFile) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}/file/${idFile}`, {
          responseType: 'blob'
        }).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Obtém timeline de um processo
  // localhost/app_dev.php/api/v2/process/{idProcess}/timeline
  getProcessTimeline (idProcess) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}/timeline`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Obtém acompanhamentos de um processo
  // localhost/app_dev.php/api/v2/process/{idProcess}/followup
  getProcessFollowUps (idProcess) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}/followup`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Obtém acompanhamentos de um processo
  // localhost/app_dev.php/api/v2/process/{idProcess}/followup
  getProcessAnswerable (idProcess) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}/answerable`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Obtém acompanhamentos de um processo
  // localhost/app_dev.php/api/v2/process/{idProcess}/followup
  getProcessInvoices (idProcess) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}/invoices`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Processos arquivos
  // localhost/app_dev.php/api/v2/process/{idProcess}/files
  getCompareTaxes (idProcess) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${idProcess}/compare/taxes`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError('getCompareTaxes: ', err)
    }
  },
  // Carrega informações para o combo do controle de bookings
  getOptions (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/options?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getProcessEquipments (processId) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/process/${processId}/equipments`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default ProcessService
