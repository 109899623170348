import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const PersonService = {
  getPersons (filter, page, itemsPerPage) {
    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 1000

    filter.page = page
    filter.per_page = itemsPerPage

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getPersonsActive (filter, page, itemsPerPage) {
    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 1000

    filter.page = page
    filter.per_page = itemsPerPage

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas/active?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getCargoAgent (filter, page, itemsPerPage) {
    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 1000

    filter.page = page
    filter.per_page = itemsPerPage

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas/agentecarga?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getTransportCompany (filter, page, itemsPerPage) {
    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 1000

    filter.page = page
    filter.per_page = itemsPerPage

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas/companhiatransporte?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getPerson (idPessoa) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas/${idPessoa}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putPersonTaxes (idPessoa, taxes) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/pessoas/${idPessoa}/taxes`, { taxes })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putPersonDefault (idPessoa, idPersonDefault) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/pessoas/${idPessoa}/persondefault`, { idTomadorNotaPadrao: idPersonDefault })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putPersonRodDefault (idPessoa, idPersonDefault) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/pessoas/${idPessoa}/persondefaultrod`, { idTomadorNotaPadrao: idPersonDefault })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putMovementType (idPessoa, idMovementType) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/pessoas/${idPessoa}/movementtype`, { tipoMovimentacaoPadrao: idMovementType })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putMovementRodType (idPessoa, idMovementType) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/pessoas/${idPessoa}/movementtyperod`, { tipoMovimentacaoPadrao: idMovementType })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putIsTaxPrefered (idPessoa, isTaxPrefered) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/pessoas/${idPessoa}/ispreferedtax`, { taxa_preferida: isTaxPrefered })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putTierFrete (idPessoa, operacao, tipoCarga, idTier) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/pessoas/${idPessoa}/tierfrete`, { idTier: idTier, operacao: operacao, tipo_carga: tipoCarga })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putTierLocal (idPessoa, operacao, tipoCarga, idTierLocal) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/pessoas/${idPessoa}/tierlocal`, { idTierLocal: idTierLocal, operacao: operacao, tipo_carga: tipoCarga })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getPeopleOptions (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas/options?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default PersonService
