<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <div>
                <div>
                  <multiple-select :options="customerList" :id-field="'id'" labelField="nome"  :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="personFetchOptions" :value="filters.filCliente" />
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'AguardandoAutorizacao' }"
                     v-on:click="applyStatusFilter('AguardandoAutorizacao')" data-toggle="tab">{{ $t('form.invoices.status.AguardandoAutorizacao') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'SolicitandoAutorizacao' }"
                     v-on:click="applyStatusFilter('SolicitandoAutorizacao')" data-toggle="tab">{{ $t('form.invoices.status.SolicitandoAutorizacao') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'AutorizacaoSolicitada' }"
                     v-on:click="applyStatusFilter('AutorizacaoSolicitada')" data-toggle="tab">{{ $t('form.invoices.status.AutorizacaoSolicitada') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'EmProcessoDeAutorizacao' }"
                     v-on:click="applyStatusFilter('EmProcessoDeAutorizacao')" data-toggle="tab">{{ $t('form.invoices.status.EmProcessoDeAutorizacao') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'AutorizadaAguardandoGeracaoPDF' }"
                     v-on:click="applyStatusFilter('AutorizadaAguardandoGeracaoPDF')" data-toggle="tab">{{ $t('form.invoices.status.AutorizadaAguardandoGeracaoPDF') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'Autorizada' }"
                     v-on:click="applyStatusFilter('Autorizada')" data-toggle="tab">{{ $t('form.invoices.status.Autorizada') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'Negada' }"
                     v-on:click="applyStatusFilter('Negada')" data-toggle="tab">{{ $t('form.invoices.status.Negada') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'Emitida' }"
                     v-on:click="applyStatusFilter('Emitida')" data-toggle="tab">{{ $t('form.invoices.status.Emitida') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'Cancelada' }"
                     v-on:click="applyStatusFilter('Cancelada')" data-toggle="tab">{{ $t('form.invoices.status.Cancelada') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'Cancelada (Portal)' }"
                     v-on:click="applyStatusFilter('Cancelada (Portal)')" data-toggle="tab">{{ $t('form.invoices.status.CanceladaPortal') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 'Erro' }"
                     v-on:click="applyStatusFilter('Erro')" data-toggle="tab">{{ $t('form.invoices.status.Erro') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <!-- Table -->
                <custom-vue-table
                  ref="vuetable"
                  http-method="get"
                  :api-url="invoiceEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="filter"
                  track-by="id_bill"
                >
                  <template slot="actions" slot-scope="props">
                    <div class="custom-actions">
                      <a class="btn btn-link btn-padding"
                         :title="$t('actions.cancel')"
                         v-on:click="handleCancelInvoice(props.rowData)">
                        <i class="text-primary fas fa-ban"></i>
                      </a>
                      <a class="btn btn-link btn-padding"
                         :title="$t('actions.download_pdf')"
                         v-on:click="handleDownloadPDF(props.rowData)">
                        <i class="text-primary far fa-file-pdf"></i>
                      </a>
                      <a class="btn btn-link btn-padding"
                         :title="$t('actions.download_xml')"
                         v-on:click="handleDownloadXML(props.rowData)">
                        <i class="text-primary far fa-file-excel"></i>
                      </a>
                      <a class="btn btn-link btn-padding"
                         :title="$t('actions.view')"
                         v-on:click="getStatusInvoice(props.rowData)">
                        <i class="text-primary rc-Ativo-210"></i>
                      </a>
                      <a class="btn btn-link btn-padding"
                         :title="$t('actions.update_status')"
                         v-on:click="refreshStatusInvoice(props.rowData)">
                        <i class="text-primary ft-refresh-ccw"></i>
                      </a>
                    </div>
                  </template>
                </custom-vue-table>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import CustomVueTable from '@/components/Table/CustomVueTable'

import IssuedStatusView from './IssuedStatusView'

import BillService from '@/services/BillService'

import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'
import PersonService from '../../../services/PersonService'

export default {
  name: 'InvoiceListBill',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('invoice.issued.title') + ' - %s'
    }
  },
  components: {
    FilterSideBar,
    CustomVueTable,
    Loading,
    FilterContainer,
    MultipleSelect
  },
  data () {
    return {
      customerList: [],
      isLoading: false,
      fullPage: false,
      selectedInvoice: null,
      invoiceDetail: false,
      invoiceEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/bill',
      multiSort: true,
      activeFilterList: {
        internal_code: null,
        customer: null,
        id_status: null
      },
      filter: {
        type: 1,
        internal_code: null,
        customer: []
      },
      fieldsTable: [
        {
          name: 'internal_code',
          title: this.$i18n.t('form.invoices.internal_code'),
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'bill_number',
          title: this.$i18n.t('form.invoices.bill_number'),
          sortField: 'billNumber',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'serie',
          title: this.$i18n.t('form.invoices.serie'),
          sortField: 'serie',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'taker_name',
          title: this.$i18n.t('form.invoices.customer'),
          sortField: 'takerName',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'emission_date_time',
          title: this.$i18n.t('form.invoices.invoice_transmission'),
          sortField: 'emissionDateTime',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatDateTime(value)
        },
        {
          name: 'total_amount',
          title: this.$i18n.t('form.invoices.amount_real'),
          sortField: 'totalAmount',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatNumber(value)
        },
        {
          name: 'status',
          title: this.$i18n.t('form.invoices.invoice_status'),
          sortField: 'status',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          width: '280px',
          dataClass: 'text-right',
          titleClass: 'text-right'
        }
      ],
      sortOrder: [
        { field: 'emissionDateTime', direction: 'desc' }
      ]
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  // Apply filters to local filter
  beforeMount () {
    this.filter.internal_code = this.$route.params.process
    this.filters.filProcesso = this.$route.params.process

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    handleCancelInvoice (bill) {
      let _this = this
      let idBill = bill.id_bill
      this.$alertSwal.modalAlert('Aviso', 'A fatura será também cancelada no sistema da prefeitura.').then((result) => {
        BillService.cancelBill(idBill).then(res => {
          _this.isLoading = false
          _this.$refs.vuetable.refresh()
        }).catch(() => {
          _this.isLoading = false
        })
      })
    },
    async handleDownloadPDF (bill) {
      this.isLoading = true
      let idBill = bill.id_bill

      let fileName = 'NOTA FISCAL CLIENTE ' + bill.id_bill + '.pdf'
      let _this = this
      this.isLoading = true
      BillService.getBillFile(idBill, 'pdf').then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    async handleDownloadXML (bill) {
      this.isLoading = true
      let idBill = bill.id_bill

      let fileName = 'NOTA FISCAL CLIENTE ' + bill.id_bill + '.xml'
      let _this = this
      this.isLoading = true
      BillService.getBillFile(idBill, 'xml').then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    refreshStatusInvoice (bill) {
      this.isLoading = true
      let idBill = bill.id_bill
      let _this = this

      BillService.refreshStatus(idBill).then(res => {
        _this.isLoading = false
        this.$refs.vuetable.refresh()
      }).catch(() => {
        _this.isLoading = false
      })
    },
    getStatusInvoice (bill) {
      this.isLoading = true
      let idBill = bill.id_bill
      let _this = this

      BillService.getBillFollowUps(idBill).then(res => {
        var ComponentClass = Vue.extend(IssuedStatusView)
        var IssuedStatusViewInstance = new ComponentClass({
          data: {
            isSuperAdmin: _this.isSuperAdmin,
            bill: res.data.data.bill,
            eventList: res.data.data.events,
            showStatus: false,
            showLogs: true
          }
        })
        IssuedStatusViewInstance.$mount()
        let issuedStatusViewContent = IssuedStatusViewInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, issuedStatusViewContent, null, 'no-padding', { showConfirmButton: false }).then((result) => {
        })

        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    personFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        PersonService.getPersons(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_schedule: object.id } }).href
    },
    buildFilters () {
      if (this.$route.params.process) {
        this.filter.internal_code = this.$route.params.process
      } else if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      this.filter.id_status = null
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.applyFilter()
    },
    // Loader
    applyFilter () {
      this.customerList = []

      // Process
      this.filters.filProcesso = this.$refs.internal_code.value

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      if (this.$refs.vuetable !== undefined) {
        this.$refs.vuetable.onFilter()
      }
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filters.filCliente = []

      this.filters.filProcesso = null
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      if (this.$refs.vuetable !== undefined) {
        this.$refs.vuetable.onFilter()
      }
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      // 1: Em aberto
      // 2: Finalizado
      // 3: Cancelado
      // 4: Parcialmente Quitado

      let statusList = [
        {
          'id': 'Emitida',
          'label': this.$i18n.t('form.invoices.status.Emitida'),
          'color': '#5ec65f'
        },
        {
          'id': 'Erro',
          'label': this.$i18n.t('form.invoices.status.Erro'),
          'color': '#ff0000'
        },
        {
          'id': 'Cancelada',
          'label': this.$i18n.t('form.invoices.status.Cancelada'),
          'color': '#f1ba26'
        },
        {
          'id': 'Cancelada (Portal)',
          'label': this.$i18n.t('form.invoices.status.CanceladaPortal'),
          'color': '#f1ba26'
        },
        {
          'id': 'Negada',
          'label': this.$i18n.t('form.invoices.status.Negada'),
          'color': '#d9534f'
        },
        {
          'id': 'Autorizada',
          'label': this.$i18n.t('form.invoices.status.Autorizada'),
          'color': '#5ec65f'
        },
        {
          'id': 'AguardandoAutorizacao',
          'label': this.$i18n.t('form.invoices.status.AguardandoAutorizacao'),
          'color': '#0275d8'
        },
        {
          'id': 'SolicitandoAutorizacao',
          'label': this.$i18n.t('form.invoices.status.SolicitandoAutorizacao'),
          'color': '#0275d8'
        },
        {
          'id': 'AutorizacaoSolicitada',
          'label': this.$i18n.t('form.invoices.status.AutorizacaoSolicitada'),
          'color': '#5bc0de'
        },
        {
          'id': 'EmProcessoDeAutorizacao',
          'label': this.$i18n.t('form.invoices.status.EmProcessoDeAutorizacao'),
          'color': '#f0ad4e'
        },
        {
          'id': 'AutorizadaAguardandoGeracaoPDF',
          'label': this.$i18n.t('form.invoices.status.AutorizadaAguardandoGeracaoPDF'),
          'color': '#f0ad4e'
        },
        {
          'id': 'EmProcessoDeCancelamento',
          'label': this.$i18n.t('form.invoices.status.EmProcessoDeCancelamento'),
          'color': '#f0ad4e'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      if (!status) {
        status = {
          label: value,
          color: '#f0ad4e'
        }
      }

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }
  .disableLabel span{
    background-color: rgba(132,117,78,0.6) !important;
    border-color: rgba(132,117,78,0.1) !important;
  }
</style>
