<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <!-- INICIO Detalhes do processo -->
        <div class="col-md-3 text-left dark-box">

          <div class="row" >
            <div class="row col-12 mb-5">
              <div  class="row col-12 mb-3">
                <div class="col-12 text-muted">Número Nf-s:</div>
                <div class="col-12 ">{{ bill.bill_number }}</div>
              </div>
              <div v-if="bill.serie" class="row col-12 mb-3">
                <div class="col-12 text-muted">Serie Nf-s</div>
                <div class="col-12 ">{{ bill.serie }}</div>
              </div>
              <div v-if="bill.taker_name" class="row col-12 mb-3">
                <div class="col-12 text-muted">Cliente</div>
                <div class="col-12 ">{{ bill.taker_name }}</div>
              </div>
              <div v-if="bill.emission_date_time" class="row col-12 mb-3">
                <div class="col-12 text-muted">Data da Emissão</div>
                <div class="col-12 ">{{ formatDateTime(bill.emission_date_time) }}</div>
              </div>
              <div v-if="bill.total_amount" class="row col-12 mb-3">
                <div class="col-12 text-muted">Valor Total</div>
                <div class="col-12 ">{{ $util.formatNumber(bill.total_amount, 2) }}</div>
              </div>
            </div>

            <div class="row col-12 mb-5">
              <div v-if="bill.status" class="row col-12 mb-3">
                <div class="col-12 text-muted">Situação</div>
                <div class="col-12 ">{{ bill.status }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIM Detalhes do processo -->
        <!-- Inicio timeline-->
        <div class="col-md-9">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>
          <ul class="nav line-tabs borderless flex-center mb-5">
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" v-bind:class="{ active: showStatus }" v-on:click="displayTimeline()" data-toggle="tab" >Status</a>-->
<!--            </li>-->
            <li class="nav-item">
              <a class="nav-link" v-bind:class="{ active: showLogs }" v-on:click="displayLogs()" data-toggle="tab" >Log</a>
            </li>
          </ul>

          <!-- Logs -->
          <div v-if="showLogs">
            <div class="table-responsive ">
                <table class="table align-middle">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in eventList" :key="index" >
                    <td>{{ formatDateTime(item.date) }}</td>
                    <td class="text-left" v-html="item.description"></td>
                  </tr>
                  <tr v-if="eventList.length <= 0">
                    <td colspan="2">Nenhum log disponível.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- FIM Logs -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessService from '@/services/Process/ProcessService'

export default {
  name: 'ProcessDetailsView',
  components: {},
  methods: {
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    displayLogs () {
      this.showStatus = false
      this.showLogs = true
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    fileDownload (idProcess, idFile, fileName) {
      this.isLoading = true
      ProcessService.getProcessFile(idProcess, idFile).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>

<style scoped>

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

</style>
